import React, { useState } from "react";
import axios from "axios"; // Import Axios
import {
  Box,
  TextField,
  Button,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import ContactUsImg from "./../../assets/images/Contactus.jpg";
import Banner from "../../constants/Banner/Banner";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    emailId: "", // Corrected the field name
    mobileNo: "", // Corrected the field name
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    emailId: "", // Corrected the field name
    mobileNo: "", // Corrected the field name
    message: "",
  });

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value
        ? ""
        : `${name.charAt(0).toUpperCase() + name.slice(1)} is required`,
      // Corrected the field name
      emailId:
        name === "emailId" && value && !validateEmail(value) // Corrected the field name
          ? "Invalid email format"
          : "",
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let hasErrors = false;
    const newErrors = {};

    for (const field in formData) {
      if (!formData[field]) {
        newErrors[field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required`;
        hasErrors = true;
      } else if (field === "emailId" && !validateEmail(formData[field])) {
        newErrors[field] = "Invalid email format";
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setErrors(newErrors);
    } else {
      // Prepare the data to send
      const dataToSend = {
        name: formData.name,
        emailId: formData.emailId,
        mobileNo: formData.mobileNo,
        message: formData.message,
      };

      try {
        // Send a POST request to the API endpoint
        const response = await axios.post(
          "https://d4pdvecv3m.execute-api.ap-south-1.amazonaws.com/dev/sendMail",
          dataToSend
        );

        // Handle the response as needed
        console.log(response.data); // Assuming the API returns data

        // Reset the form
        setFormData({
          name: "",
          emailId: "",
          mobileNo: "",
          message: "",
        });
        setErrors({
          name: "",
          emailId: "",
          mobileNo: "",
          message: "",
        });
      } catch (error) {
        // Handle any errors that occur during the API request
        console.error("Error sending data:", error);
        // You can set an error state here if needed.
      }
    }
  };

  return (
    <>
      <Banner
        Image={ContactUsImg}
        BannerHeading={"Contact Us"}
        IsHomeBanner={false}
      ></Banner>

      <Box className={"ContactForm"} p={8}>
        <Container maxWidth="sm">
          <Typography variant="h4" align="center" gutterBottom>
            Contact Us
          </Typography>
          <Typography
            textAlign={"center"}
            variant="h6"
            fontWeight={"bold"}
            p={4}
          >
            FOR ANY QUERIES WRITE TO US.
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  name="name"
                  fullWidth
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  name="emailId" // Corrected the field name
                  fullWidth
                  value={formData.emailId}
                  onChange={handleChange}
                  error={!!errors.emailId} // Corrected the field name
                  helperText={errors.emailId} // Corrected the field name
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Mobile"
                  name="mobileNo" // Corrected the field name
                  fullWidth
                  value={formData.mobileNo}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  name="message"
                  multiline
                  fullWidth
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default ContactForm;
