export const NavItems = [
  {
    path: "/aboutUs",
    pageName: "About Us",
  },
  {
    path: "#",
    pageName: "Our Works",
    children: [
      {
        path: "/education",
        pageName: "Education",
      },
      {
        path: "/womenEmpowerment",
        pageName: "Women Empowerment",
      },
    ],
  },
  {
    path: "#",
    pageName: "Campaign",
    children: [
      {
        path: "/nayasavera",
        pageName: "EK Naya Savera",
      },
      // {
      //   path: "/vision2030",
      //   pageName: "Vision2030",
      // },
    ],
  },
  {
    path: "/getInvolved",
    pageName: "Get Involved",
    children: [
      {
        path: "/IndividualSupport",
        pageName: "Individual Support",
      },
      {
        path: "/CorporatePartnerships",
        pageName: "Corporate Partnerships",
      },
      {
        path: "/SchoolPartnerships",
        pageName: "School Partnerships",
      },
      // {
      //   path: "/Internship",
      //   pageName: "Internship",
      // },
    ],
  },
  {
    path: "#",
    pageName: "Media Center",
    children: [
      {
        path: "/workshopAndEvents",
        pageName: "Workshop & Events",
      },
      {
        path: "Achivements",
        pageName: "Our Town Achivers",
      },
    ],
  },
  {
    path: "/resourceCenter",
    pageName: "Resource Center",
    children: [
      {
        path: "/tietblogs",
        pageName: "TIET Blogs",
      },
      {
        path: "Carrers",
        pageName: "Carrer Chart",
      },
      // {
      //   path: "/ApplicationForm",
      //   pageName: "Application Form",
      // },
      {
        path: "https://img1.wsimg.com/blobby/go/bc47c574-c20f-4114-84a4-39fc7cbc26cf/downloads/Women%20-%20Application%20for%20free%20computer%20course%201.pdf?ver=1672525350979",
        pageName: "Application Form (For Women)",
        download: "true",
      },
      {
        path: "https://img1.wsimg.com/blobby/go/bc47c574-c20f-4114-84a4-39fc7cbc26cf/downloads/Application%20for%20free%20computer%20course%201%20(1).pdf?ver=1672525350980",
        pageName: "Application Form (For Men)",
        download: "true",
      },
    ],
  },

  {
    path: "/donateus",
    pageName: "DonateUs",
  },
  {
    path: "/contactUs",
    pageName: "Contact Us",
  },
  // {
  //   path: "/login",
  //   pageName: "Login",
  // },
  // {
  //   path: "/admindashboard",
  //   pageName: "Admin",
  // },
];
