// import React from "react";
// import { Carousel } from "react-responsive-carousel";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Grid, Paper, Typography } from "@mui/material";

// const AlbumPage = () => {
//   const images = [
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/adnan.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Affan-ds.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/CA_Intermediate.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/hamdan.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/cma.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/hafeez_mbbs.jpeg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/maaz.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/MubashshirLLB.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/nadeem_ds.jpeg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/NadeemCruise.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/rayan.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/saffan.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/shuaib.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/ShuhaibJEE.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Aarif_flyer1.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Dr_Nadeem.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Nasser_flyer.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet-alaaadnan.png",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/TIETTEMP1.jpg",
//     "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Zeeshan.png",
//   ];

//   return (
//     <Grid container spacing={2} justifyContent="center">
//       <Grid item xs={12} md={10}>
//         <Typography variant="h4" align="center" gutterBottom>
//           Achievements Album
//         </Typography>
//         <Paper elevation={3} sx={{ padding: 2 }}>
//           <Carousel
//             autoPlay
//             interval={3000}
//             showStatus={false}
//             infiniteLoop
//             showArrows={true}
//             showIndicators={true}
//             width="100%"
//             emulateTouch={true}
//             style={{ maxWidth: "100%", maxHeight: 200 }}
//           >
//             {images.map((image, index) => (
//               <div key={index}>
//                 <img
//                   src={image}
//                   alt={`achievement-${index}`}
//                   style={{ maxWidth: "100%", height: "auto" }}
//                 />
//               </div>
//             ))}
//           </Carousel>
//         </Paper>
//       </Grid>
//     </Grid>
//   );
// };

// export default AlbumPage;

// Congrats_Album.js

import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Grid, Paper, Typography } from "@mui/material";

const Congrats_Album = () => {
  const images = [
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/adnan.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Affan-ds.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/CA_Intermediate.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/hamdan.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/cma.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/hafeez_mbbs.jpeg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/maaz.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/MubashshirLLB.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/nadeem_ds.jpeg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/NadeemCruise.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/rayan.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/saffan.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/shuaib.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/ShuhaibJEE.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Aarif_flyer1.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Dr_Nadeem.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet_Nasser_flyer.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Tiet-alaaadnan.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/TIETTEMP1.jpg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Zeeshan.png",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Aatifa.jpeg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Mubeena.jpeg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Razeema.jpeg",
    "https://tiet-congrats.s3.ap-south-1.amazonaws.com/daniya.jpeg",
  ];

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} md={10}>
        <Typography
          variant="h4"
          align="center"
          fontWeight={"bold"}
          gutterBottom
        >
          TIET Achievements Album
        </Typography>
        <Paper elevation={3} sx={{ padding: 2, maxWidth: 800, margin: "auto" }}>
          <Carousel
            autoPlay
            interval={3000}
            showStatus={false}
            infiniteLoop
            showArrows={true}
            showIndicators={true}
            width="100%"
            emulateTouch={true}
            thumbWidth={50} // Adjust thumbnail size
          >
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`achievement-${index}`}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: 750,
                    objectFit: "contain",
                  }}
                />
              </div>
            ))}
          </Carousel>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Congrats_Album;
