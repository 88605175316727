import React from 'react'
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const QuotedContent = (props) => {
    return (
        <p className="quotedPara">
            <FormatQuoteIcon />
            {props.content}
        </p>
    )
}

export default QuotedContent