import React from 'react'
import { Box, Paper, Grid, Container, Typography } from '@mui/material';

const ImageContent = (props) => {
    return (
        <Box>
            <Container>
                <Paper>
                    <Grid container direction={props.reverse ? "row-reverse" : ''}>
                        <Grid item xs={12} md={4}>
                            <img src={props.Image} style={{ maxWidth: "100%" }} alt={props.Image} />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Box sx={{p: {xs: 4, md: 8}}} textAlign={'center'} display={'flex'} flexDirection={'column'} justifyContent={'center'} height={'100%'}>
                                <Typography variant='h4' gutterBottom>{props.Heading}</Typography>
                                <Typography variant="p">{props.Paragraph}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}

export default ImageContent