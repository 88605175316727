import React from "react";
import { YouTube, Instagram, Facebook } from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

let SocialMedia = [
  {
    link: "https://www.facebook.com/199995206793030",
    icon: <Facebook />,
    name: "Facebook",
  },
  {
    link: "https://www.twitter.com/tietpbt",
    icon: <XIcon />,
    name: "Twitter",
  },
  {
    link: "https://www.instagram.com/tietpbt",
    icon: <Instagram />,
    name: "Instagram",
  },
  {
    link: "https://www.youtube.com/channel/UCogMV1pIWXhkwed1z4wPoEQ/videos",
    icon: <YouTube />,
    name: "Youtube",
  },
];

const SocialMediaWidget = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {/* // <Box
    //   sx={{
    //     position: "fixed",
    //     top: "80%",
    //     right: "20px",
    //     transform: "translateY(-50%)",
    //     display: "flex",
    //     flexDirection: "column",
    //     gap: "10px",
    //   }}
    // >
    //   <IconButton
    //     aria-label="YouTube"
    //     href="https://www.youtube.com/channel/UCogMV1pIWXhkwed1z4wPoEQ/videos"
    //     target="_blank"
    //     style={{ color: "#FF0000" }} // Change color to YouTube's red
    //   >
    //     <YouTube />
    //   </IconButton>
    //   <IconButton
    //     aria-label="Instagram"
    //     href="https://www.instagram.com/tietpbt"
    //     target="_blank"
    //     style={{ color: "#E4405F" }} // Change color to Instagram's pink
    //   >
    //     <Instagram />
    //   </IconButton>
    //   <IconButton
    //     aria-label="Twitter"
    //     href="https://www.twitter.com/tietpbt"
    //     target="_blank"
    //     style={{ color: "#1DA1F2" }} // Change color to Twitter's blue
    //   >
    //     <Twitter />
    //   </IconButton>
    //   <IconButton
    //     aria-label="Facebook"
    //     href="https://www.facebook.com/199995206793030"
    //     target="_blank"
    //     style={{ color: "#1877F2" }} // Change color to Facebook's blue
    //   >
    //     <Facebook />
    //   </IconButton>
    // </Box> */}
      <SpeedDial
        ariaLabel="SpeedDial controlled open example"
        className="floatingSocialIcon"
        sx={{ position: "fixed", bottom: 46, right: 16, zIndex: "0" }}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {SocialMedia.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            component="a"
            target="_blank"
            href={action.link}
            onClick={handleClose}
          />
        ))}
      </SpeedDial>
    </>
  );
};

export default SocialMediaWidget;
