import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import TietLogo from "./../../assets/images/TietLogo.jpg";

const WhatsAppWidgetIcon = () => {
  return (
    <WhatsAppWidget
      phoneNo="918148911041"
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={5000}
      messageBox={true}
      //   messageBoxTxt="Hi Team, is there any related service available ?"
      iconSize="40"
      iconColor="white"
      iconBgColor="green"
      headerIcon={TietLogo}
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="grey"
      headerTitle="TIET"
      headerCaption="Online"
      bodyBgColor="#bbb"
      chatPersonName="Support"
      chatMessage={
        <>
          Hi there 👋 <br />
          <br /> How can I help you?
        </>
      }
      footerBgColor="#999"
      placeholder="Type a message.."
      btnBgColor="lime"
      btnTxt="Start Chat"
      btnTxtColor="black"
    />
  );
};

export default WhatsAppWidgetIcon;
