import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Button,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import ComputerIcon from "@mui/icons-material/Computer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import TietLogo from "./../../assets/images/logo.jpg";

function AdminScreen() {
  // State for drawer open/close
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Function to toggle the drawer
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{ display: "flex" }}>
      {/* App Bar */}
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar>
          <Button color="inherit" onClick={handleDrawerToggle}>
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </Button>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            TIET
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Left Side Navigation Drawer */}
      <Drawer
        variant="temporary"
        open={drawerOpen}
        onClose={handleDrawerToggle}
      >
        <div style={{ width: 240 }}>
          {/* Logo from the local directory */}
          <div style={{ padding: "16px", textAlign: "center" }}>
            <img
              src={TietLogo}
              alt="Logo"
              style={{ width: "80%", maxHeight: "100px", objectFit: "contain" }}
            />
          </div>

          <List>
            <ListItem button component={Link} to="/dashboard">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            <ListItem button component={Link} to="/student-list">
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Student List" />
            </ListItem>
            <ListItem button component={Link} to="/computer-registration">
              <ListItemIcon>
                <ComputerIcon />
              </ListItemIcon>
              <ListItemText primary="Computer Class Reg" />
            </ListItem>
            <ListItem button component={Link} to="/english-registration">
              <ListItemIcon>
                <MenuBookIcon />
              </ListItemIcon>
              <ListItemText primary="English Class Reg" />
            </ListItem>
          </List>
        </div>
      </Drawer>

      {/* Main Content */}
      <Container
        component="main"
        maxWidth="xs"
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          marginLeft: drawerOpen ? 240 : 0,
          transition: "margin 0.2s ease-out",
        }}
      >
        {/* Empty Page Content */}
        <Toolbar />
        <div style={{ flexGrow: 1 }}>
          <h1>Dashboard</h1>
          <p>Add your content here.</p>
        </div>
      </Container>
    </div>
  );
}

export default AdminScreen;
