import React from "react";
import { Box } from "@mui/material";
import BackgroundVideo from "../../constants/BackgroundVideo/BackgroundVideo";

import PeopleIcon from "@mui/icons-material/People";
import LaptopIcon from "@mui/icons-material/Laptop";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

import "./nayasavera.scss";

const listValues = [
  {
    logo: <PeopleIcon />,
    Title: "Lorem ipsum",
    Para: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    logo: <LaptopIcon />,
    Title: "Lorem ipsum",
    Para: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    logo: <MonetizationOnIcon />,
    Title: "Lorem ipsum",
    Para: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    logo: <PeopleIcon />,
    Title: "Lorem ipsum",
    Para: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
  {
    logo: <LaptopIcon />,
    Title: "Lorem ipsum",
    Para: "Lorem ipsum dolor sit amet, consectetur adipiscing.",
  },
];

const nayasavera = () => {
  return (
    <>
      <Box className={"overlayBG"}>
        <BackgroundVideo />
      </Box>
      {/* <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
        <Container>
          <QuotedContent
            content={
              "Revival of a community is a complex process that demands the sacrifice of collective resources, manpower, money, and energy for a common good cause.Given the historical, socio-cultural, and existential need, comprehensive, holistic reforms in all walks of life must be thought of. Reforms in education is one of the major steps that need to be taken by the Muslim community in general across the country but more so in Pernambut.Education alone can liberate the community from various impending shackles. The current overall education system in Pernambut requires holistic revamp.TIET is committed to assist the community through appropriate guidance for attaining quality education, skill development, career building and other societal economic development works through education."
            }
          />
        </Container>
      </Box> */}

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        {/* <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            What Our Supporters Says
          </Typography>
          <Grid container spacing={2}>
            {Campaign_supportImages.map((campaign_support, key) => (
              <Grid item xs={4} key={key}>
                <GridContent
                  GridImage={campaign_support.Image}
                  Heading={campaign_support.Title}
                  Paragraph={campaign_support.Paragraph}
                />
              </Grid>
            ))}
          </Grid>
          <Box textAlign={'center'} mt={5}>
            <Button variant="contained" size="large" component={Link} to="" target='_blank'>
              View All
            </Button>
          </Box>
        </Container> */}
      </Box>
    </>
  );
};

export default nayasavera;
