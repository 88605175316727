import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button"; // Import Button component

// Sample data (replace it with your actual data)
const students = [
  {
    id: 1,
    name: "John Doe",
    phoneNumber: "123-456-7890",
    email: "john@example.com",
    address: "123 Main St",
  },
  // Add more student objects as needed
];

// Columns for the Table
const columns = [
  { id: "id", label: "ID", minWidth: 70 },
  { id: "name", label: "Student Name", minWidth: 200 },
  { id: "phoneNumber", label: "Phone Number", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 200 },
  { id: "address", label: "Address", minWidth: 300 },
];

const StudentInformationPage = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(students);

  // Handle search bar input change
  const handleSearchChange = (e) => {
    const searchInput = e.target.value.toLowerCase();
    setSearchText(searchInput);

    // Filter students based on name or email containing the search input
    const filteredData = students.filter(
      (student) =>
        student.name.toLowerCase().includes(searchInput) ||
        student.email.toLowerCase().includes(searchInput)
    );

    setFilteredStudents(filteredData);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Student Information
      </Typography>

      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          label="Search by Name or Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchText}
          onChange={handleSearchChange}
          style={{ width: "50%", marginRight: "8px" }} // Adjusted width to 50%
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleSearchChange}
        >
          Search
        </Button>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#eeeeee",
                  }} // Grey background color
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <TableRow key={student.id}>
                {columns.map((column) => (
                  <TableCell key={column.id}>{student[column.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default StudentInformationPage;
