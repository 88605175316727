// import React from "react";
// import SunburstChart from "./../ResourceCenter/sunburstchart";
// import CarrerTree from "./../ResourceCenter/treechart";

// const Careers = () => {
//   // Define width and height for the SunburstChart

//   return (
//     <div
//     style={{
//       display: "flex",
//       width: "100%",
//       alignItems: "center",
//       justifyContent: "center",
//     }}
//     >
//       <div
//         className="chart-container"
//         style={{
//           width: "500px",
//           height: "auto",
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         {/* Pass width and height as props */}
//         <SunburstChart width={width} height={height} />
//         <CarrerTree />
//       </div>
//       Other content or components can go here
//     </div>
//   );
// };

// export default Careers;

import React from "react";
import CarrerTree from "./../ResourceCenter/treechart";

// Component to render the full tree chart
const FullTreeChart = () => {
  return (
    <div
      style={{
        width: "100%",
        // display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      <CarrerTree />
    </div>
  );
};

export default FullTreeChart;
