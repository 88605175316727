import React from "react";
import { Box, Container, Typography } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";

import { ComputerRounded } from "@mui/icons-material";
import { CastForEducationOutlined } from "@mui/icons-material";
import ImageCarousel from "../../constants/ImageCarousel/ImageCarousel";
import EducationImg1 from "./../../assets/images/education_img/education_2.jpg";
import EducationImg2 from "./../../assets/images/education_img/education_3.jpg";
import "../OurWorks/Ourwork.scss";

const educationCarousel = [EducationImg1, EducationImg2];

const Heading = (props) => {
  const { Title, TitleHeading, IsTextCenter } = props;
  return (
    <>
      {IsTextCenter ? (
        <Typography
          variant="subtitle1"
          marginBottom={1}
          color="primary"
          textTransform={"uppercase"}
          textAlign={"center"}
        >
          {Title}
        </Typography>
      ) : (
        <Typography
          variant="h6"
          marginBottom={2.5}
          color="primary"
          textTransform={"uppercase"}
        >
          {Title}
        </Typography>
      )}
      <Typography
        variant="h4"
        marginBottom={2.5}
        fontWeight={"bold"}
        textAlign={IsTextCenter ? "center" : ""}
      >
        {TitleHeading}
      </Typography>
    </>
  );
};
const OurWorks = [
  {
    step: "01",
    heading: "Career Guidance",
    body: "Choosing a career path can be a daunting task, especially for young students. At TIET, we offer career counseling services to help students explore their interests, passions, and aptitudes and guide them towards a meaningful profession. Our experienced counselors work directly with students, providing them with valuable insights and guidance to help them make informed decisions about their future.",
    icon: <SchoolIcon />,
  },
  {
    step: "02",
    heading: "Special Tuition for 10th & 12th Grade Students",
    body: "The 10th and 12th board examinations are significant milestones in a student's academic journey. TIET offers specialized tutoring for students taking these exams, ensuring they have the support and guidance they need to excel. Our expert educators create a positive learning environment where students can clarify their doubts, reinforce their knowledge, and improve their performance.",
    icon: <EmojiObjectsIcon />,
  },
  {
    step: "03",
    heading: "Industrial Awareness and College Visits",
    body: "We understand the importance of exposing students to real-world experiences and giving them a glimpse into their future careers. That's why we organize industrial visits and college tours where students can learn about different industries, see real-life applications of classroom learning, and interact with professionals. These experiences broaden their horizons and inspire them to pursue their aspirations.",
    icon: <SpatialTrackingIcon />,
  },
  {
    step: "04",
    heading: "Awareness Program for Government Jobs and Exams",
    body: "Many students from disadvantaged backgrounds lack awareness and guidance regarding government job opportunities. TIET conducts awareness programs to educate students about various government jobs and examinations, provides study resources and coaching to help them crack these exams, and empowers them to secure a brighter future.",
    icon: <CastForEducationOutlined />,
  },
  {
    step: "05",
    heading: "Computer Courses for Boys and Girls",
    body: "In today's digital era, computer literacy is essential for future success. However, many underprivileged children have limited access to computers and computer education. At TIET, we offer computer education to both boys and girls, bridging the digital divide and equipping them with the necessary digital skills to thrive in society.",
    icon: <ComputerRounded />,
  },
];

const Education = () => {
  return (
    <>
      <Box className={"sections homebanner"}>
        <ImageCarousel carouselItems={educationCarousel} itemsShow={1} />
        <Typography
          variant="h3"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white", // Change color as needed
            textAlign: "center",
            zIndex: 2,
            width: "100%",
            padding: 24,
          }}
        >
          Empowering Minds, Changing Lives, Combating Illiteracy
        </Typography>
      </Box>
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Why Education?
          </Typography>
          <Typography variant="body1">
            Education is a fundamental human right and a powerful tool for
            transforming individuals, communities, and nations. It opens new
            opportunities by equipping individuals with the skills and knowledge
            they need to thrive in life. At TIET, we believe that every child,
            regardless of their background or circumstances, deserves access to
            quality education. We are committed to making a lasting impact by
            providing educational opportunities to underprivileged children and
            youth.
          </Typography>
        </Container>
      </Box>

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Our Mission
          </Typography>
          <Typography variant="body1">
            At TIET, our mission is to educate, inspire, and empower the next
            generation. We aim to remove barriers to education and create a
            platform for students to realize their full potential. Through our
            various initiatives, we seek to equip students with the skills and
            support they need to achieve their academic goals and thrive in
            their chosen careers.
          </Typography>
        </Container>
      </Box>

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            What We Do?
          </Typography>
          <Typography variant="body1">
            At TIET, we recognize that education is more than just academics; it
            is a holistic journey that encompasses many aspects of personal and
            professional development. That's why we take a comprehensive
            approach to education, addressing not only academic needs but also
            career guidance, industry awareness, and overall personal growth.
            Our goal is to cultivate well-rounded individuals who are prepared
            to tackle the challenges of a rapidly changing world.
          </Typography>
        </Container>
      </Box>
      <Box className={"sections"} py={10}>
        <Container>
          <Heading
            Title={""}
            TitleHeading={"Our Approach !"}
            IsTextCenter={true}
          />
          <Box p={2}></Box>
          <Box
            className="infographyListItems1"
            maxWidth={"720px"}
            margin={"auto"}
          >
            {OurWorks.map((item, index) => (
              <Box className={"list-" + `${index}`} key={index}>
                <Typography variant="body2" className="steps">
                  Step<span>{item.step}</span>
                </Typography>
                <Box className={"infographyContent"}>
                  <Typography variant="h6">{item.heading}</Typography>
                  <Typography component={"div"} variant="body1">
                    {item.body}
                  </Typography>
                </Box>
                <Box className={"icon"}>{item.icon}</Box>
              </Box>
            ))}
          </Box>
        </Container>
      </Box>

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Join Us to Transform Lives Through Education
          </Typography>
          <Typography variant="body1">
            At TIET, we believe that education is a powerful tool for changing
            lives and shaping a better future. Join us in our mission to empower
            the next generation by supporting our various programs and making a
            positive impact on the lives of underprivileged children and youth.
            Together, we can work towards creating a more equitable and brighter
            world through education.
          </Typography>
        </Container>
      </Box>
      {/* <Box className={"sections"} p={8} px={{xs: 0, md: 8}}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Last Year's Impact
          </Typography>
        </Container>
      </Box> */}

      {/* <Box className={"sections"} p={5}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Stories of Change
          </Typography>
          <Grid container spacing={2}>
            {gridData.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <GridContent
                  GridImage={item.logo}
                  Heading={item.heading}
                  Paragraph={item.description}
                  readMore={true}
                />
              </Grid>
            ))}
          </Grid>
          <Box textAlign={"center"} marginTop={5}>
            <img src={stories} alt="01" width={"900px"} />
            <Button variant="contained">Read More</Button>
          </Box>
        </Container>
      </Box> */}
    </>
  );
};

export default Education;
