import React from "react";
import { Box, Container } from "@mui/material";
import Support from "./../../assets/images/bussiness-partnership.jpg";
import Banner from "../../constants/Banner/Banner";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

const individualSupport = () => {
  return (
    <>
      <Banner
        Image={Support}
        BannerHeading={"Harnessing the Strength of Community"}
        IsHomeBanner={true}
      ></Banner>

      <Box className={"sections"} p={8} px={{xs: 4, md: 8}}>
        <Container>
          <p className="quotedPara">
            <FormatQuoteIcon />
            At TIET, we hold conviction in making a positive difference in our
            community by empowering our young through education and training. By
            collaborating with corporations, we hope to bridge the gap between
            education and employment by offering internship opportunities to our
            trained students. Through this collaboration, we intend to not only
            improve our students' abilities and knowledge, but also provide them
            vital experience working in a corporate office. We firmly think that
            this relationship will benefit both students and companies.
          </p>
        </Container>
      </Box>
    </>
  );
};

export default individualSupport;
