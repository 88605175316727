import React from "react";
import { Container } from "@mui/material";
import SocialMediaWidget from "./../SocialMediaComponent/Socialmedia";

const Layout = ({ children }) => {
  return (
    <Container>
      {children}
      <SocialMediaWidget />
    </Container>
  );
};

export default Layout;
