import Team from "./../../assets/images/team.png";
import History from "./../../assets/images/history.jpg";
import Mission from "./../../assets/images/mission.jpg";

export const AboutUs = [
  {
    ourTeam: [
      {
        Image: Team,
        Heading: "Our Team",
        //Content: "TIET is an registered NGO backed by passionate professionals of Pernambut. Joined hands to take their motherland to greater  heights. Our amazing team of regulars and part-time volunteers are committed to helping others. Think, you would be a good fit? Get in touch for more information!"
        Content:
          "TIET is a registered NGO, championing a cause to elevate the standard of the people of our hometown Pernambut through Education, Advancement and Empowerment. A team of Empowered, Educated, Passionate, committed professionals of Pernambut, joining hands to take their motherland to greater heights. The mission of the team is to see that every young eye that dreams of a future be fulfilled. " +
          " Our team meets bright youngsters to help them prepare for a prospective career with a goal to empower the youth for an empowered society and an empowered nation. The dreams that their parents had would be fulfilled through their children by providing the source, resource, platform and make them confident Achievers.",
      },
    ],
    ourHistory: [
      {
        Image: History,
        Heading: "Our History",
        //Content: "Since its inception in 2011, TIET has successfully completed number of projects ranging from academic coaching, Skill Training, Computer Training, spoken English programs, workshops, seminars, fully funded-scholarships to the needy, career placements etc"
        Content:
          "TIET saw its dawn in 2011. With an aim to nurture the enthusiasm and zeal of its founder members to take up projects to fulfil their dreams of seeing the Sun rising through these years with success stories, several projects, ranging from academic coaching to providing total scholarship to students from deserving families  were taken up. Skill-based training such as computer-skills, communicative skills, coding, tally, along with exposure to higher learning through seminars, workshops and career placements . We plan, work, and execute similar need based innovative  projects to fulfil the mission and vision the organisation.",
      },
    ],
    ourMission: [
      {
        Image: Mission,
        Heading: "Our Mission",
        //Content: "TIET is committed to assist the Unemployed Youths/students  through appropriate guidance for attaining quality education, skill development and career building. TIET’s vision is to harness the inbuilt talent of young Unemployed Youths/Students, motivate them to think and dream big so as to achieve higher goals."
        Content:
          "To envision a society with employed committed youth who work for growth and prosperity of the community at large " +
          " To guide and motivate the youth towards acquiring quality education, skill development and career building for a bright future" +
          " To inspire them to dream big, think beyond the sky and work smart to achieve higher goals ",
      },
    ],
  },
];
