import React, { useState, useEffect } from "react";
import { Box, Container, Grid, Typography, Rating } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ImageCarousel from "../../constants/ImageCarousel/ImageCarousel";
import WomenEducationImg1 from "../../assets/images/education_img/WomenEmpowerment.jpg";
import WomenEducationImg2 from "../../assets/images/education_img/WomenEmpowerment2.jpg";

const WomenEducationCarousel = [WomenEducationImg1, WomenEducationImg2];

const stories = [
  {
    quote:
      "This class has been immensely beneficial for all of us, and I thoroughly enjoyed every moment of it. Previously, my English skills were lacking, but after attending this class, I have noticed a significant improvement in both my language proficiency and confidence levels. I have been able to correct my grammatical mistakes, and the syllabus covered has proven to be very practical. I find myself learning more effectively, acquiring new words every day. Our tutor was incredibly friendly, and her teaching style made the material easily understandable for all of us. Thank you, TIET",
    user: "--Sumaiya",
    rating: 5,
  },
  {
    quote:
      "I wanted to take a moment to express my sincere appreciation for the IT session that took place earlier today. It gives me great pleasure to inform you that the session went exceptionally well, and I couldn't be more pleased with the outcome. The IT session was meticulously planned and flawlessly executed. The content was informative, relevant, and engaging, and the presenters demonstrated a deep understanding of the subject matter. The discussions and interactive elements allowed for a dynamic exchange of ideas and best practices among the participants.",
    user: "--Mujahida Fathin",
    rating: 5,
  },
  // Add more stories as needed
];

const StoriesCarousel = () => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStoryIndex((prevIndex) =>
        prevIndex === stories.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change 5000 to the desired interval in milliseconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box
          border={1}
          borderColor="primary.main"
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
        >
          {/* Star ratings */}
          <Box display="flex" alignItems="center">
            <Rating
              name="rating"
              value={stories[currentStoryIndex].rating}
              readOnly
            />
          </Box>
          {/* Quote icon and text */}
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" alignItems="center">
              <FormatQuoteIcon color="primary" fontSize="large" />
              <Typography variant="body1" fontStyle="italic" ml={2}>
                {stories[currentStoryIndex].quote}
              </Typography>
              <FormatQuoteIcon color="primary" fontSize="large" rotate={180} />
            </Box>
            {/* User name */}
            <Typography variant="subtitle1" align="center">
              {stories[currentStoryIndex].user}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const WomenEmpowerment = () => {
  return (
    <>
      {/* Image Carousel */}
      <Box className={"sections homebanner"}>
        <ImageCarousel
          carouselItems={WomenEducationCarousel}
          itemsShow={1}
          style={{ opacity: 0.9 }}
        />
        <Typography
          variant="h3"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white", // Change color as needed
            textAlign: "center",
            zIndex: 2,
            width: "100%",
            padding: 24,
          }}
        >
          Championing change, Empowering HER
        </Typography>
      </Box>

      {/* Introduction */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="body1">
            Welcome to TIET, a dedicated NGO with a clear goal: to empower
            women. Our members who have banded together to create a nurturing
            and empowering atmosphere in which women may grow and attain their
            full potential.
          </Typography>
        </Container>
      </Box>

      {/* Why is it necessary to empower women? */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Why is it necessary to empower women?
          </Typography>
          <Typography variant="body1">
            At TIET, we think that empowering women is not just a question of
            justice and basic human rights, but also an essential step toward
            creating a better society. Women who are empowered become change
            agents, not just for themselves, but also for their families,
            communities, and, eventually, the globe. Every woman has distinct
            qualities and abilities that, with the appropriate opportunity and
            support, may have a tremendous influence.
          </Typography>
        </Container>
      </Box>

      {/* What We Do */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            What We Do ?
          </Typography>
          <Typography variant="body1">
            One of the primary ways we encourage women's empowerment is through
            education. Our committed team of female volunteers works diligently
            to train girl pupils and provide them with necessary life skills and
            information. We also offer computer and spoken English lessons to
            help them improve their employment and communication abilities. By
            investing in young girls' education, we are tearing down barriers
            and paving the way for a better future.
          </Typography>
        </Container>
      </Box>

      {/* Prolonged Approach */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Prolonged Approach
          </Typography>
          <Typography variant="body1">
            TIET believes that genuine empowerment requires a thorough and
            ongoing strategy. This is why we also provide scholarships to
            underprivileged girls, enabling them to pursue quality education
            that would otherwise be out of reach. By giving these girls an
            education, we are helping them break the cycle of poverty and create
            a brighter future for themselves and their communities.
          </Typography>
        </Container>
      </Box>

      {/* Join the Movement */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Join the Movement
          </Typography>
          <Typography variant="body1">
            TIET is a network of women who support other women. We are dedicated
            to creating a society where every woman can thrive and achieve her
            full potential. Join us in our mission to empower women and make a
            positive impact on the world. Let's work together to create a
            brighter tomorrow.
          </Typography>
          <Typography variant="body1" paddingTop={2}>
            Remember: when women are empowered, everyone benefits. So, let's
            stand up for women's empowerment and work together to build a
            society where every woman can shine. We are TIET - empowering women
            and communities.
          </Typography>
        </Container>
      </Box>

      {/* Last Year's Impact */}
      {/* <Box className={"sections"} p={8} px={{xs: 0, md: 8}}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Last Year's Impact
          </Typography>
        </Container>
      </Box> */}

      {/* Stories of Change */}
      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography variant="h5" fontWeight="bold" gutterBottom>
            Stories of Change
          </Typography>
          <StoriesCarousel />
        </Container>
      </Box>
    </>
  );
};

export default WomenEmpowerment;
