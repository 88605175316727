import React from "react";
import { Box, Container } from "@mui/material";
import FundRaise from "./../../assets/images/donate/fundraise.jpg";
import Banner from "../../constants/Banner/Banner";

const DonateUs = () => {
  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Donate Us"}
        IsHomeBanner={false}
      />
      <Box className={"DonateUs"} p={8}>
        <Container>
          <Box>
            <iframe
              src="https://pages.razorpay.com/pl_GHwsKhyZZZB7Mg/view"
              title="description"
              width="100%" // Adjusted width to match the banner width
              height={600} // Adjust the height as needed
              style={{ overflow: "hidden" }}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default DonateUs;
