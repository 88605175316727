import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

import FundRaise from "./../../assets/images/donate/fundraise.jpg";

import Banner from "../../constants/Banner/Banner";

const DonateUs = () => {
  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Dashboard Management"}
        IsHomeBanner={false}
      ></Banner>

      {/* <Box>
        <img src={Fundraise1} alt="01" width={"100%"} />
      </Box>
      <Box>
        <img src={Fundraise2} alt="02" width={"100%"} />
      </Box> */}
    </>
  );
};

export default DonateUs;
