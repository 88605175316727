import React from "react";
import {  Box } from "@mui/material";

import CommingSoon from "./../../assets/images/CommingSoon.jpg"

const TietBlogs = () => {
  return (
    <>
      <Box textAlign={'center'}>
        <img src={CommingSoon} alt="01" width={"55%"} height={"550px"} />
      </Box>
    </>
  );
};

export default TietBlogs;
