import React from "react";
import { Box, Container } from "@mui/material";
import Congrats_Album from "./Congrats_Album";

const Achievements = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 1000, // Adjust the maxWidth as needed
          padding: 2,
        }}
      >
        <Congrats_Album />
      </Box>
    </Container>
  );
};

export default Achievements;
