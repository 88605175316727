import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

// Sample data (replace it with your actual data)
const students = [
  {
    id: 1,
    name: "John Doe",
    phoneNumber: "123-456-7890",
    email: "john@example.com",
    address: "123 Main St",
    qualification: "Bachelor's Degree",
    timeSlot: "Morning",
  },
  // Add more student objects as needed
];

// Columns for the Table
const columns = [
  { id: "id", label: "ID", minWidth: 50 },
  { id: "name", label: "Name", minWidth: 150 },
  { id: "email", label: "Email", minWidth: 200 },
  { id: "phoneNumber", label: "Phone", minWidth: 120 },
  { id: "qualification", label: "Qualification", minWidth: 150 },
  { id: "timeSlot", label: "Time Slot", minWidth: 100 },
  { id: "action", label: "Action", minWidth: 150 },
];

const ComputerCourseData = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredStudents, setFilteredStudents] = useState(students);

  // Handle search bar input change
  const handleSearchChange = (e) => {
    const searchInput = e.target.value.toLowerCase();
    setSearchText(searchInput);

    // Filter students based on name or email containing the search input
    const filteredData = students.filter(
      (student) =>
        student.name.toLowerCase().includes(searchInput) ||
        student.email.toLowerCase().includes(searchInput)
    );

    setFilteredStudents(filteredData);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Computer Course Registration Information
      </Typography>

      <div style={{ display: "flex", alignItems: "center", marginBottom: 16 }}>
        <TextField
          label="Search by Name or Email"
          fullWidth
          margin="normal"
          value={searchText}
          onChange={handleSearchChange}
          size="small"
          style={{
            width: "50%", // Set the width to 50%
            borderRadius: "24px", // Adjust the value for more or less rounded edges
            background: "#f1f3f4", // Light grey background color
            boxShadow: "none", // Remove the box shadow
            border: "1px solid #e0e0e0", // Set border color
          }}
          InputProps={{
            startAdornment: (
              <SearchIcon
                style={{ color: "#5f6368", marginRight: "8px" }} // Grey color for the search icon
              />
            ),
          }}
        />

        {/* Add some space between search bar and Add button */}
        <div style={{ marginLeft: "8px" }}>
          <Button
            variant="contained"
            color="primary" // Blue color for the Add button
            style={{ borderRadius: "24px" }}
            startIcon={<AddIcon />}
            // Add functionality for adding user
          >
            Add User
          </Button>
        </div>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    backgroundColor: "#E0E0E0", // Grey background color
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredStudents.map((student) => (
              <TableRow key={student.id}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    {column.id !== "action" ? (
                      student[column.id]
                    ) : (
                      <>
                        <Button
                          color="primary" // Blue color for the Edit button
                          variant="outlined"
                          style={{ marginRight: "8px" }}
                        >
                          Edit
                        </Button>
                        <Button
                          color="secondary" // Red color for the Delete button
                          variant="outlined"
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ComputerCourseData;
