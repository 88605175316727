import supporter1 from "../images/support/Mr.ImranAhmed.jpg";
import supporter2 from "../images/support/AqeelAhmed.jpg";
import supporter3 from "../images/support/Hassan Ahmed.jpg";

export const SupportersContent = [
  {
    Image: supporter1,
    Title: "How to build your Carrer in IT",
    Paragraph: "Your Roadmap to IT Career Mastery in the Digital Age",
  },
  {
    Image: supporter3,
    Title: "How does the Internet Works?",
    Paragraph: "A Glimpse on Computer Networking! ",
  },
  {
    Image: supporter2,
    Title: "How to build your Carrer in IT",
    Paragraph:
      "Navigate your path in IT by acquiring skills, gaining experience, and fostering a strong network to build a successful career.",
  },
];
