import React from 'react'
import "./style.scss"
import { Box, Button, Typography } from '@mui/material'
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { Link } from 'react-router-dom';

const Banner = (props) => {
    return (
        <div className={`bannerSection ${props.IsHomeBanner ? "homeBanner" : "subBanner"}`} style={{ backgroundImage: `url(${props.Image})` }}>
            {props.IsHomeBanner ?
                <Box textAlign={'center'} px={{xs:3, md:6}}>
                    <Typography variant="h2" color={'white'} fontWeight={'bold'} gutterBottom>{props.BannerHeading}</Typography>
                    <Button variant="contained" startIcon={<VolunteerActivismIcon />} size="large" component={Link} to="https://rzp.io/l/pqlTYtD" target='_blank'>
                        Donate TIET
                    </Button>
                </Box>
                :
                <Box p={6}>
                    <Typography variant="h2" color={'#fff'} textAlign={'center'}>{props.BannerHeading}</Typography>
                </Box>
            }
        </div>
    )
}

export default Banner