import React, { useState } from "react";
import {
  Container,
  Typography,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

function FreeComputerCourseApplication() {
  const [formData, setFormData] = useState({
    studentName: "",
    fathersName: "",
    fathersOccupation: "",
    educationQualification: "",
    category: "",
    address: "",
    sourceOfInformation: "",
    mobileNumber: "",
    email: "",
    timeSlot: "",
  });

  const [errors, setErrors] = useState({
    studentName: "",
    fathersName: "",
    fathersOccupation: "",
    educationQualification: "",
    category: "",
    address: "",
    sourceOfInformation: "",
    mobileNumber: "",
    email: "",
    timeSlot: "",
  });

  const [confirmation, setConfirmation] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateMobileNumber = (value) => {
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(value);
  };

  const validateEmail = (value) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    // Validate studentName
    if (formData.studentName.trim() === "") {
      newErrors.studentName = "Student name is required";
    }

    // Validate fathersName
    if (formData.fathersName.trim() === "") {
      newErrors.fathersName = "Father's name is required";
    }

    // Validate fathersOccupation
    if (formData.fathersOccupation.trim() === "") {
      newErrors.fathersOccupation = "Father's occupation is required";
    }

    // Validate educationQualification
    if (formData.educationQualification.trim() === "") {
      newErrors.educationQualification = "Education qualification is required";
    }

    // Validate category
    if (formData.category === "") {
      newErrors.category = "Category is required";
    }

    // Validate address
    if (formData.address.trim() === "") {
      newErrors.address = "Address is required";
    }

    // Validate sourceOfInformation
    if (formData.sourceOfInformation === "") {
      newErrors.sourceOfInformation = "Source of information is required";
    }

    // Validate mobile number
    if (!validateMobileNumber(formData.mobileNumber)) {
      newErrors.mobileNumber = "Invalid mobile number";
    }

    // Validate email
    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate timeSlot
    if (formData.timeSlot === "") {
      newErrors.timeSlot = "Time slot is required";
    }

    // Check if the confirmation checkbox is checked
    if (!confirmation) {
      alert("Please confirm that the information is correct.");
      return;
    }

    // Set the new errors object
    setErrors(newErrors);

    // Submit data if validation passes
    if (Object.keys(newErrors).length === 0) {
      // Perform form submission logic here
    }
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Free Computer Course Application
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Student Name"
              name="studentName"
              fullWidth
              required
              value={formData.studentName}
              onChange={handleChange}
              error={!!errors.studentName}
              helperText={errors.studentName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Father's Name"
              name="fathersName"
              fullWidth
              required
              value={formData.fathersName}
              onChange={handleChange}
              error={!!errors.fathersName}
              helperText={errors.fathersName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Father's Occupation"
              name="fathersOccupation"
              fullWidth
              required
              value={formData.fathersOccupation}
              onChange={handleChange}
              error={!!errors.fathersOccupation}
              helperText={errors.fathersOccupation}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Education Qualification"
              name="educationQualification"
              fullWidth
              required
              value={formData.educationQualification}
              onChange={handleChange}
              error={!!errors.educationQualification}
              helperText={errors.educationQualification}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Category</InputLabel>
              <Select
                name="category"
                value={formData.category}
                onChange={handleChange}
                error={!!errors.category}
              >
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Employee">Employee</MenuItem>
                <MenuItem value="Fresh Graduate">Fresh Graduate</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Address for Communication"
              name="address"
              fullWidth
              required
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Source of Information</InputLabel>
              <Select
                name="sourceOfInformation"
                value={formData.sourceOfInformation}
                onChange={handleChange}
                error={!!errors.sourceOfInformation}
              >
                <MenuItem value="Social Media">Social Media</MenuItem>
                <MenuItem value="School">School</MenuItem>
                <MenuItem value="College">College</MenuItem>
                <MenuItem value="Flyers">Flyers</MenuItem>
                <MenuItem value="Friends">Friends</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Mobile Number"
              name="mobileNumber"
              fullWidth
              required
              value={formData.mobileNumber}
              onChange={handleChange}
              error={!!errors.mobileNumber}
              helperText={errors.mobileNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              name="email"
              fullWidth
              required
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Time Slot Preferred</InputLabel>
              <Select
                name="timeSlot"
                value={formData.timeSlot}
                onChange={handleChange}
                error={!!errors.timeSlot}
              >
                <MenuItem value="3pm to 5pm">3pm to 5pm</MenuItem>
                <MenuItem value="5pm to 7pm">5pm to 7pm</MenuItem>
                <MenuItem value="7pm to 9pm">7pm to 9pm</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmation}
                  onChange={(e) => setConfirmation(e.target.checked)}
                />
              }
              label="The above furnished information of mine/my ward is correct to the best of my knowledge and belief."
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default FreeComputerCourseApplication;
