import React from "react";
import { Box, Container, Grid, Typography, Rating } from "@mui/material";
import { useState, useEffect } from "react";

// import Support from "./../../assets/images/support1.jpg";
import Support from "./../../assets/images/support1.jpg";
import Banner from "../../constants/Banner/Banner";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { SupportersContent } from "../../assets/json/Supporters";
import { Supporters_saysContent } from "../../assets/json/supporters_says";
import GridContent from "../../constants/GridContent/GridContent";

import "./GetInvolved.scss";

const stories = [
  {
    quote:
      "The chance to engage with TIET, an NGO, has been truly gratifying for me. I am genuinely impressed by the impactful work undertaken by TIET. This experience has strengthened my resolve to contribute my support to the organization to the best of my ability.",
    user: "--Imran Ahmed",
    rating: 5,
  },
  {
    quote:
      "In my perspective, teaching stands out as the noblest of professions. It is through the dedication of educators that the minds of tomorrow are shaped, thereby influencing the destiny of nations. Best wishes to TIET.",
    user: "--Aqeel Ahmed",
    rating: 5,
  },
  // Add more stories as needed
];
const StoriesCarousel = () => {
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStoryIndex((prevIndex) =>
        prevIndex === stories.length - 1 ? 0 : prevIndex + 1
      );
    }, 10000); // Change 5000 to the desired interval in milliseconds
    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box
          border={1}
          borderColor="primary.main"
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="column"
        >
          {/* Star ratings */}
          <Box display="flex" alignItems="center">
            <Rating
              name="rating"
              value={stories[currentStoryIndex].rating}
              readOnly
            />
          </Box>
          {/* Quote icon and text */}
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box display="flex" alignItems="center">
              <FormatQuoteIcon color="primary" fontSize="large" />
              <Typography variant="body1" fontStyle="italic" ml={2}>
                {stories[currentStoryIndex].quote}
              </Typography>
              <FormatQuoteIcon color="primary" fontSize="large" rotate={180} />
            </Box>
            {/* User name */}
            <Typography variant="subtitle1" align="center">
              {stories[currentStoryIndex].user}
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
const individualSupport = () => {
  return (
    <>
      <Banner
        Image={Support}
        BannerHeading={"The Power of Personalization"}
        IsHomeBanner={true}
      ></Banner>

      <Box className={"sections"} p={8} px={{ xs: 4, md: 8 }}>
        <Container>
          <p className="quotedPara">
            <FormatQuoteIcon />
            Individual support allows individuals to help others in need by
            providing career guidance, training, workshops, and awareness
            campaigns to students. We believe that by connecting students with
            experts from diverse sectors, we can help them succeed in their
            chosen careers. Individuals interested in this program come from a
            variety of professions and are ready to share their skills and
            knowledge with students, therefore positively impacting their lives.
            Through one-day workshops, we have the opportunity to shape a better
            future for these youngsters and contribute to our goal of
            establishing a more fair society. Together, we are making a
            difference.
          </p>
        </Container>
      </Box>
      <Box className={"sections supportInAction"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            Supporters in Action
          </Typography>
          <Grid container spacing={2}>
            {SupportersContent.map((supporters, key) => (
              <Grid item md={4} key={key}>
                <GridContent
                  GridImage={supporters.Image}
                  Heading={supporters.Title}
                  Paragraph={supporters.Paragraph}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box className={"sections"} p={8} px={{ xs: 0, md: 8 }}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            What Our Supporters Says
          </Typography>
          <StoriesCarousel />
        </Container>
      </Box>
    </>
  );
};

export default individualSupport;
