import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import loginBannerImage from "./../../assets/images/banner1.jpg";
import Logo from "./../../assets/images/logo.jpg";
import { Box, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = (data) => {
    let errors = {};

    if (!data.email) {
      errors.email = "Email is required";
    }

    if (!data.password) {
      errors.password = "Password is required";
    }

    return errors;
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        background: `url(${loginBannerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          padding={5}
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(255, 255, 255, 0.92)", // Opacity set to 0.92
            boxShadow: "0px 2px 30px #ccc6",
          }}
        >
          <Box mb={3}>
            <Box mb={2}>
              <img src={Logo} alt="" style={{ height: "40px" }} />
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
          </Box>
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email ? true : false}
            helperText={errors.email}
            fullWidth
            margin="normal"
          />
          <br />
          <TextField
            label="Password"
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            error={errors.password ? true : false}
            helperText={errors.password}
            fullWidth
            margin="normal"
          />
          <Box my={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              component={Link}
              to={"/myForms"}
              size="large"
            >
              Sign In
            </Button>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={3}
          >
            <Divider sx={{ width: "40%" }} />
            <Typography component="body1">or</Typography>
            <Divider sx={{ width: "40%" }} />
          </Box>
          <Box textAlign={"center"}>
            <Typography component="body1">
              Don't have an account?{" "}
              <Button component={Link} to={"/SignUp"} color="primary">
                Sign Up Now
              </Button>
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Login;
