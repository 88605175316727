import { Card, CardContent, CardHeader, CardMedia, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'


const GridContent = (props) => {
    return (
        <Card style={{ height: '100%' }}>
            <CardMedia
                component="img"
                height="194"
                image={props.GridImage}
                alt="Paella dish"
            />
            <CardHeader title={props.Heading} style={{ paddingBottom: 0 }} />
            <CardContent>
                <Typography variant="body1" color="text.secondary">
                    {props.Paragraph}
                </Typography>
                {props.readMore ?
                    <div style={{ textAlign: "right", marginTop: '20px' }}>
                        <Link
                            variant="body2"
                            href="#"
                            color="primary"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Read More
                        </Link>
                    </div>
                    :
                    ""
                }
            </CardContent>

        </Card>
    )
}

export default GridContent