import React from "react";
import TietVideo from './../../assets/videos/ekNayaSavera.mp4'


const BackgroundVideo = () => {
  return (
    <>
      <video autoplay="auto" muted loop style={{ width: '100%' }}>
        <source src={TietVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
  );
};

export default BackgroundVideo;
