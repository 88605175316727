import React, { useState } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helperTreeChart";

// const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
//   <g>
//     <rect width="20" height="20" x="-10" onClick={toggleNode} />
//     <text fill="black" strokeWidth="1" x="-50">
//       {nodeDatum.name}
//     </text>
//   </g>
// );

const OrgChartTree = () => {
  const [dimensions, translate, containerRef] = useCenteredTree();
  const [treeData, setTreeData] = useState(careerData);
  const [selectedNode, setSelectedNode] = useState(null);

  const handleNodeClick = (nodeData, event) => {
    // Log the nodeData to inspect its structure
    console.log(nodeData);
    setSelectedNode(nodeData.data);
  };

  return (
    <div style={{ display: "flex", height: "100vh" }} ref={containerRef}>
      <div style={{ width: "80%" }}>
        <Tree
          zoom="0.8"
          // renderCustomNodeElement={renderRectSvgNode}
          data={treeData}
          // orientation="vertical"
          initialDepth={0}
          // separation={{ siblings: 1, nonSiblings: 1 }}
          // nodeSize={{ x: 150, y: 150 }}
          // translate={{ x: 50, y: 50 }}
          dimensions={dimensions}
          translate={translate}
          collapsible={true}
          onNodeClick={handleNodeClick}
          shouldCollapseNeighborNodes={true}
          enableLegacyTransitions={true} // Enable legacy transitions
        />
      </div>
      {/* <div
        style={{
          width: "20%",
          backgroundColor: "#f0f0f0",
          padding: "10px",
          border: "1px solid #ddd",
          borderRadius: "5px",
        }}
      >
        {selectedNode && (
          <div
            style={{
              background: "#f9f9f9",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <h2 style={{ color: "#333" }}>Carrer Details:</h2>
            <p>
              <strong style={{ color: "#555" }}>Name:</strong>{" "}
              {selectedNode.name}
            </p>
            <p>
              <strong style={{ color: "#555" }}>Details:</strong>
            </p>
          </div>
        )}
      </div> */}
    </div>
  );
};

// Define careerData here

// const careerData = {
//   collapsed: false,
//   name: "Click here to Choose Your Careers",
//   children: [
//     {
//       name: "After 10th",
//       children: [
//         {
//           name: "< 1 year",
//           children: [
//             { name: "Data Entry Operator" },
//             { name: "Personal Fitness Trainer" },
//             { name: "Digital Marketing" },
//             { name: "Diploma in Agriculture Management" },
//             { name: "Data Mining" },
//             { name: "Government Contractor (Civil/Electrical)" },
//             {
//               name: "ITI (Fitter, welder, Machinist, DTP Diesel Mechanic etc.)",
//             },
//             { name: "Art Teacher Diploma" },
//           ],
//         },
//         {
//           name: "1 year",
//           children: [{ name: "Fire & Safety" }, { name: "Interior Design" }],
//         },
//         {
//           name: "2 years",
//           children: [
//             { name: "Medical Laboratory Technician Certificate Course" },
//             { name: "Desktop Publishing" },
//             { name: "Private Secretary Practice" },
//             { name: "Beauty Culture & Hair Dressing" },
//             { name: "Garment Technology" },
//           ],
//         },
//         {
//           name: "3 years",
//           children: [{ name: "Merchant Navy" }],
//         },
//       ],
//     },
//     {
//       name: "After 12th",
//       children: [
//         {
//           name: "Commerce",
//           children: [
//             {
//               name: "1 year",
//               children: [
//                 { name: "Manager/Business" },
//                 { name: "Bachelor of Management" },
//                 { name: "B.P.Ed" },
//                 { name: "P.T Teacher" },
//               ],
//             },
//             {
//               name: "2 years",
//               children: [
//                 { name: "B.Ed" },
//                 { name: "U.G Diploma Courses" },
//                 { name: "SSC" },
//                 { name: "MPSC" },
//                 { name: "UPSC" },
//               ],
//             },
//             {
//               name: "3 years",
//               children: [
//                 { name: "B.A" },
//                 { name: "BMS" },
//                 { name: "IATA" },
//                 { name: "CA" },
//                 { name: "ICWA" },
//                 { name: "C.S" },
//                 { name: "B.Com" },
//                 { name: "Stock Market" },
//                 { name: "NSE/BSE" },
//                 { name: "Financial Analyst" },
//                 { name: "Indian Military Academy (IAS class-1 officer)" },
//               ],
//             },
//             {
//               name: "5 years",
//               children: [
//                 { name: "B.EI.Ed" },
//                 { name: "Bachelor in Humanities & Social Sciences" },
//                 { name: "BBA" },
//                 { name: "BAF" },
//                 { name: "BBI" },
//               ],
//             },
//           ],
//         },
//         {
//           name: "Arts",
//           children: [
//             {
//               name: "< 1 year",
//               children: [{ name: "Content Writing or Softskills training" }],
//             },
//             {
//               name: "1 year",
//               children: [
//                 { name: "Manager/Business" },
//                 { name: "Bachelor of Management" },
//                 { name: "B.P.Ed" },
//                 { name: "P.T Teacher" },
//               ],
//             },
//             {
//               name: "2 years",
//               children: [
//                 { name: "B.Ed" },
//                 { name: "MA in mass communication" },
//                 { name: "Sub Inspector Exam for BSF/CRPF/CISF" },
//                 { name: "Advertising & Commercial Management Diploma" },
//               ],
//             },
//             {
//               name: "3 years",
//               children: [
//                 { name: "MA" },
//                 { name: "MA in mass communication" },
//                 { name: "UPSC Exam(IAS Officer)" },
//               ],
//             },
//           ],
//         },
//         {
//           name: "Science",
//           children: [
//             {
//               name: "With PCMB",
//               children: [
//                 {
//                   name: "2 years",
//                   children: [
//                     { name: "D.Pharm" },
//                     { name: "Bsc Electronics" },
//                     { name: "MCA" },
//                     { name: "MBA" },
//                     { name: "Msc Bio Technology" },
//                     { name: "Msc Electronics" },
//                   ],
//                 },
//                 {
//                   name: "3 years",
//                   children: [
//                     { name: "Bsc Bio Technology" },
//                     { name: "Bsc Nursing" },
//                     { name: "Msc" },
//                     { name: "M.Phil" },
//                     { name: "M.Tech Bio-Tech" },
//                     { name: "Phd Nursing" },
//                     { name: "Special Diploma" },
//                     { name: "Paramedical Courses" },
//                     { name: "B.sc Home Science" },
//                   ],
//                 },
//                 {
//                   name: "4 years",
//                   children: [
//                     { name: "B.Physiotherapy" },
//                     { name: "B.Arch" },
//                     { name: "BCA/BCS" },
//                     { name: "B.Sc Statistics to Actuarist" },
//                   ],
//                 },
//                 {
//                   name: "5 years",
//                   children: [{ name: "Phd Electronics" }, { name: "Pharm D" }],
//                 },
//                 {
//                   name: "6 years",
//                   children: [{ name: "Pharm D" }],
//                 },
//               ],
//             },
//             {
//               name: "With PCB",
//               children: [
//                 {
//                   name: "Less than 1 year",
//                   children: [
//                     { name: "Content Writing or Softskills training" },
//                   ],
//                 },
//                 {
//                   name: "1 year",
//                   children: [
//                     { name: "Manager/Business" },
//                     { name: "Bachelor of Management" },
//                     { name: "B.P.Ed" },
//                     { name: "P.T Teacher" },
//                   ],
//                 },
//                 {
//                   name: "2 years",
//                   children: [
//                     { name: "B.Ed" },
//                     { name: "MA in mass communication" },
//                     { name: "Sub Inspector Exam for BSF/CRPF/CISF" },
//                     { name: "Advertising & Commercial Management Diploma" },
//                   ],
//                 },
//                 {
//                   name: "3 years",
//                   children: [
//                     { name: "MA" },
//                     { name: "MA in mass communication" },
//                     { name: "UPSC Exam(IAS Officer)" },
//                   ],
//                 },
//               ],
//             },
//             {
//               name: "With PCM",
//               children: [
//                 {
//                   name: "1 year",
//                   children: [{ name: "Merchant Navy" }],
//                 },
//                 {
//                   name: "2 years",
//                   children: [
//                     { name: "M.E" },
//                     { name: "Teacher" },
//                     { name: "MPSC" },
//                     { name: "UPSC Exam(IAS Officer)" },
//                     { name: "Defence Direct Entry" },
//                     { name: "Indian Navy" },
//                     { name: "Airforce" },
//                     { name: "M.S Foreign University" },
//                   ],
//                 },
//                 {
//                   name: "3 years",
//                   children: [
//                     { name: "N.D.A" },
//                     { name: "Army" },
//                     { name: "Job in Railway" },
//                     { name: "Job in public sector company" },
//                     { name: "M.Tech (IIT)" },
//                   ],
//                 },
//                 {
//                   name: "4 years",
//                   children: [{ name: "B.E" }, { name: "B.Tech" }],
//                 },
//                 {
//                   name: "5 years",
//                   children: [
//                     { name: "B.Arch" },
//                     { name: "Diploma in Interior / Landscape Design" },
//                     { name: "Bachelor of planning & Design" },
//                     { name: "MCA" },
//                     { name: "M.Sc.IT" },
//                     { name: "M.C.S" },
//                     { name: "M.C.M" },
//                     { name: "M.B.A" },
//                   ],
//                 },
//               ],
//             },
//           ],
//         },
//       ],
//     },
//   ],
// };

const careerData = {
  collapsed: false,
  name: "Click here to Choose Your Careers",
  children: [
    {
      name: "After 10th",
      children: [
        {
          name: "< 1 year",
          children: [
            { name: "Data Entry Operator" },
            { name: "Personal Fitness Trainer" },
            { name: "Digital Marketing" },
            { name: "Diploma in Agriculture Management" },
            { name: "Data Mining" },
            { name: "Government Contractor (Civil/Electrical)" },
            {
              name: "ITI (Fitter, welder, Machinist, DTP Diesel Mechanic etc.)",
            },
            { name: "Art Teacher Diploma" },
          ],
        },
        {
          name: "1 year",
          children: [{ name: "Fire & Safety" }, { name: "Interior Design" }],
        },
        {
          name: "2 years",
          children: [
            { name: "Medical Laboratory Technician Certificate Course" },
            { name: "Desktop Publishing" },
            { name: "Private Secretary Practice" },
            { name: "Beauty Culture & Hair Dressing" },
            { name: "Garment Technology" },
          ],
        },
        {
          name: "3 years",
          children: [{ name: "Merchant Navy" }],
        },
      ],
    },
    {
      name: "After 12th",
      children: [
        {
          name: "Commerce",
          children: [
            {
              name: "1 year",
              children: [
                { name: "Manager/Business" },
                { name: "Bachelor of Management" },
                { name: "B.P.Ed" },
                { name: "P.T Teacher" },
              ],
            },
            {
              name: "2 years",
              children: [
                { name: "B.Ed" },
                { name: "U.G Diploma Courses" },
                { name: "SSC" },
                { name: "MPSC" },
                { name: "UPSC" },
              ],
            },
            {
              name: "3 years",
              children: [
                { name: "B.A" },
                { name: "BMS" },
                { name: "IATA" },
                { name: "CA" },
                { name: "ICWA" },
                { name: "C.S" },
                { name: "B.Com" },
                { name: "Stock Market" },
                { name: "NSE/BSE" },
                { name: "Financial Analyst" },
                { name: "Indian Military Academy (IAS class-1 officer)" },
              ],
            },
            {
              name: "5 years",
              children: [
                { name: "B.EI.Ed" },
                { name: "Bachelor in Humanities & Social Sciences" },
                { name: "BBA" },
                { name: "BAF" },
                { name: "BBI" },
              ],
            },
          ],
        },
        {
          name: "Arts",
          children: [
            {
              name: "< 1 year",
              children: [{ name: "Content Writing or Softskills training" }],
            },
            {
              name: "1 year",
              children: [
                { name: "Manager/Business" },
                { name: "Bachelor of Management" },
                { name: "B.P.Ed" },
                { name: "P.T Teacher" },
              ],
            },
            {
              name: "2 years",
              children: [
                { name: "B.Ed" },
                { name: "MA in mass communication" },
                { name: "Sub Inspector Exam for BSF/CRPF/CISF" },
                { name: "Advertising & Commercial Management Diploma" },
              ],
            },
            {
              name: "3 years",
              children: [
                { name: "MA" },
                { name: "MA in mass communication" },
                { name: "UPSC Exam(IAS Officer)" },
              ],
            },
          ],
        },
        {
          name: "Science",
          children: [
            {
              name: "With PCMB",
              children: [
                {
                  name: "2 years",
                  children: [
                    { name: "D.Pharm" },
                    { name: "Bsc Electronics" },
                    { name: "MCA" },
                    { name: "MBA" },
                    { name: "Msc Bio Technology" },
                    { name: "Msc Electronics" },
                  ],
                },
                {
                  name: "3 years",
                  children: [
                    { name: "Bsc Bio Technology" },
                    { name: "Bsc Nursing" },
                    { name: "Msc" },
                    { name: "M.Phil" },
                    { name: "M.Tech Bio-Tech" },
                    { name: "Phd Nursing" },
                    { name: "Special Diploma" },
                    { name: "Paramedical Courses" },
                    { name: "B.sc Home Science" },
                  ],
                },
                {
                  name: "4 years",
                  children: [
                    { name: "B.Physiotherapy" },
                    { name: "BCA/BCS" },
                    { name: "B.Sc Statistics to Actuarist" },
                  ],
                },
                {
                  name: "5 years",
                  children: [
                    { name: "Phd Electronics" },
                    { name: "Pharm D" },
                    { name: "MBBS" },
                  ],
                },
                {
                  name: "6 years",
                  children: [{ name: "Pharm D" }],
                },
              ],
            },
            {
              name: "With PCB",
              children: [
                {
                  name: "Less than 1 year",
                  children: [
                    { name: "Content Writing or Softskills training" },
                  ],
                },
                {
                  name: "1 year",
                  children: [
                    { name: "Manager/Business" },
                    { name: "Bachelor of Management" },
                    { name: "B.P.Ed" },
                    { name: "P.T Teacher" },
                  ],
                },
                {
                  name: "2 years",
                  children: [
                    { name: "B.Ed" },
                    { name: "MA in mass communication" },
                    { name: "Sub Inspector Exam for BSF/CRPF/CISF" },
                    { name: "Advertising & Commercial Management Diploma" },
                  ],
                },
                {
                  name: "3 years",
                  children: [
                    { name: "MA" },
                    { name: "MA in mass communication" },
                    { name: "UPSC Exam(IAS Officer)" },
                  ],
                },
              ],
            },
            {
              name: "With PCM",
              children: [
                {
                  name: "1 year",
                  children: [{ name: "Merchant Navy" }],
                },
                {
                  name: "2 years",
                  children: [
                    { name: "M.E" },
                    { name: "Teacher" },
                    { name: "MPSC" },
                    { name: "UPSC Exam(IAS Officer)" },
                    { name: "Defence Direct Entry" },
                    { name: "Indian Navy" },
                    { name: "Airforce" },
                    { name: "M.S Foreign University" },
                  ],
                },
                {
                  name: "3 years",
                  children: [
                    { name: "N.D.A" },
                    { name: "Army" },
                    { name: "Job in Railway" },
                    { name: "Job in public sector company" },
                    { name: "M.Tech (IIT)" },
                  ],
                },
                {
                  name: "4 years",
                  children: [{ name: "B.E" }, { name: "B.Tech" }],
                },
                {
                  name: "5 years",
                  children: [
                    { name: "B.Arch" },
                    { name: "Diploma in Interior / Landscape Design" },
                    { name: "Bachelor of planning & Design" },
                    { name: "MCA" },
                    { name: "M.Sc.IT" },
                    { name: "M.C.S" },
                    { name: "M.C.M" },
                    { name: "M.B.A" },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default OrgChartTree;
