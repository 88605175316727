// import React from "react";
// import Banner from "../../constants/Banner/Banner";
// import FundRaise from "./../../assets/images/donate/fundraise.jpg";
// import { Box, Container, Grid, Typography } from "@mui/material";
// import GridContent from "../../constants/GridContent/GridContent";
// // import storiesOfChange01 from "./../../assets/images/education_img/storiesOfChange/01.jpg";
// // import storiesOfChange02 from "./../../assets/images/education_img/storiesOfChange/02.jpg";
// // import storiesOfChange03 from "./../../assets/images/education_img/storiesOfChange/03.jpg";
// import MotivationImg1 from "./../../assets/images/MotivationalPrograms/Motivation1.jpeg";
// import MotivationImg2 from "./../../assets/images/MotivationalPrograms/Motivation2.jpeg";
// import MotivationImg3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
// import MotivationImg4 from "./../../assets/images/MotivationalPrograms/Motivation4.jpeg";

// const gridData = [
//   {
//     logo: MotivationImg1,
//   },
//   {
//     logo: MotivationImg2,
//   },
//   {
//     logo: MotivationImg3,
//   },
//   {
//     logo: MotivationImg4,
//   },
//   //   {
//   //     logo: storiesOfChange03,
//   //   },
// ];

// const WorkshopAndEventsDetails = () => {
//   return (
//     <>
//       <Banner
//         Image={FundRaise}
//         BannerHeading={"Workshop & Events"}
//         IsHomeBanner={false}
//       ></Banner>
//       <Box className={"sections"} p={5}>
//         <Container>
//           <Typography
//             variant="h5"
//             fontWeight={"bold"}
//             textAlign={"center"}
//             marginBottom={3}
//           >
//             Motivational Program
//           </Typography>
//           <Grid container spacing={2}>
//             {gridData.map((item, index) => (
//               <Grid item sm={6} md={4} key={index}>
//                 <GridContent GridImage={item.logo} />
//               </Grid>
//             ))}
//           </Grid>
//         </Container>
//       </Box>
//     </>
//   );
// };

// export default WorkshopAndEventsDetails;

import React from "react";
import Banner from "../../constants/Banner/Banner";
import FundRaise from "./../../assets/images/donate/fundraise.jpg";
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";

import MotivationImg1 from "./../../assets/images/MotivationalPrograms/Motivation1.jpeg";
import MotivationImg2 from "./../../assets/images/MotivationalPrograms/Motivation2.jpeg";
import MotivationImg3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
import MotivationImg4 from "./../../assets/images/MotivationalPrograms/Motivation4.jpeg";

const GridContent = ({ GridImage }) => {
  return (
    <Card>
      <CardMedia
        component="img"
        alt="Grid Content"
        height="200"
        image={GridImage}
        style={{ objectFit: "cover", marginBottom: 0 }} // Adjust marginBottom as needed
      />
    </Card>
  );
};

const WorkshopAndEventsDetails = () => {
  const gridData = [
    { logo: MotivationImg1 },
    { logo: MotivationImg2 },
    { logo: MotivationImg3 },
    { logo: MotivationImg4 },
  ];

  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Workshop & Events"}
        IsHomeBanner={false}
      ></Banner>
      <Box className={"sections"} p={5}>
        <Container>
          <Typography
            variant="h5"
            fontWeight={"bold"}
            textAlign={"center"}
            marginBottom={3}
          >
            Motivational Program
          </Typography>
          <Grid container spacing={2}>
            {gridData.map((item, index) => (
              <Grid item sm={6} md={4} key={index}>
                <GridContent GridImage={item.logo} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default WorkshopAndEventsDetails;
