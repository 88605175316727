import { Box, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const CountSetTimeout = (props) => {
  const intervalRef = useRef();
  const countRef = useRef();

  const { number, incrementBy, duration } = props;

  const [count, setCount] = useState("0");

  useEffect(() => {
    let start = 0;
    const end = parseInt(number);
    if (start === end) return;

    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    let counter = () => {
      intervalRef.current = setTimeout(() => {
        
        start += 1;
        
        setCount(String(start));

        counter();

        if (start === end) {
          clearTimeout(intervalRef.current);
        }
      }, incrementTime);
    };

    counter();

  }, [number, incrementBy, duration]);

  return (
    <>
      <Box ref={countRef} className="Count" textAlign={"center"}>
        <Typography variant="h2" fontWeight={"bold"}>
          {count} +
        </Typography>
        <Typography variant="h5" mt={3} mb={5}>
          {props.title}
        </Typography>
      </Box>

    </>
  );
};
export default CountSetTimeout;