import React from 'react'
import Banner from '../../constants/Banner/Banner'
import BannerImage from "./../../assets/images/about-us.jpg"
import ImageContent from '../../constants/ImageContent/ImageContent'
import { AboutUs } from "../../assets/json/AboutUs"
import { Box } from '@mui/material'

const OurTeam = () => {
    return (
        <>
            <Banner Image={BannerImage} BannerHeading={"About Us"} IsHomeBanner={false}></Banner>
            <Box py={10}>
                {AboutUs[0].ourTeam.map((value, key) => (
                    <ImageContent key={key} Image={value.Image} Heading={value.Heading} Paragraph={value.Content} />
                ))}

                {AboutUs[0].ourMission.map((value, key) => (
                    <ImageContent key={key} Image={value.Image} Heading={value.Heading} Paragraph={value.Content} reverse={true} />
                ))}

                {AboutUs[0].ourHistory.map((value, key) => (
                    <ImageContent key={key} Image={value.Image} Heading={value.Heading} Paragraph={value.Content} />
                ))}
            </Box>
        </>
    )
}

export default OurTeam