import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FundRaise from "./../../assets/images/donate/fundraise.jpg";
import Banner from "../../constants/Banner/Banner";

// Images Import
import Counselling1 from "./../../assets/images/Education Counselling/Counselling1.jpeg";
import Counselling2 from "./../../assets/images/Education Counselling/Counselling2.jpeg";
import Counselling3 from "./../../assets/images/Education Counselling/Counselling3.jpeg";
import Counselling5 from "./../../assets/images/Education Counselling/Counselling6.jpeg";

import Motivation3 from "./../../assets/images/MotivationalPrograms/Motivation3.jpeg";
import Motivation10 from "./../../assets/images/MotivationalPrograms/Motivation10.jpg";
import Motivation9 from "./../../assets/images/MotivationalPrograms/Motivation9.jpg";
import Motivation2 from "./../../assets/images/MotivationalPrograms/Motivation2.jpeg";
import Motivation5 from "./../../assets/images/MotivationalPrograms/Motivatioan5.jpg";
import Motivation6 from "./../../assets/images/MotivationalPrograms/Motivation6.jpeg";
import Motivation7 from "./../../assets/images/MotivationalPrograms/Motivatioan7.jpg";
import Motivation8 from "./../../assets/images/MotivationalPrograms/Motivation8.jpeg";

import PublicTalk4 from "./../../assets/images/PublicTalks/PublicTalk4.jpeg";
import PublicTalk5 from "./../../assets/images/PublicTalks/PublicTalk5.jpg";
import PublicTalk2 from "./../../assets/images/PublicTalks/PublicTalk2.jpg";
import PublicTalk1 from "./../../assets/images/PublicTalks/PublicTalk1.jpeg";
import PublicTalk6 from "./../../assets/images/PublicTalks/PublicTalk6.jpg";
import PublicTalk7 from "./../../assets/images/PublicTalks/PublicTalk7.jpg";
import PublicTalk8 from "./../../assets/images/PublicTalks/PublicTalk8.jpeg";
import PublicTalk9 from "./../../assets/images/PublicTalks/PublicTalk9.jpeg";

import EducationalTour1 from "./../../assets/images/EducationTour/Tour1.jpeg";
import EducationalTour2 from "./../../assets/images/EducationTour/Tour2.jpeg";
import EducationalTour3 from "./../../assets/images/EducationTour/Tour3.jpeg";
import EducationalTour4 from "./../../assets/images/EducationTour/Tour4.jpeg";
import EducationalTour5 from "./../../assets/images/EducationTour/Tour5.jpeg";
import EducationalTour6 from "./../../assets/images/EducationTour/Tour6.jpeg";
import EducationalTour7 from "./../../assets/images/EducationTour/Tour7.jpeg";
import EducationalTour8 from "./../../assets/images/EducationTour/Tour8.jpeg";

import LibraryImg1 from "./../../assets/images/ComputerClasswithLibrary/Library2.jpeg";
import ComputerClassImg1 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass1.jpeg";
import ComputerClassImg3 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass3.jpeg";
import ComputerClassImg4 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass4.jpeg";
import LibraryImg7 from "./../../assets/images/ComputerClasswithLibrary/Library7.jpg";
import LibraryImg8 from "./../../assets/images/ComputerClasswithLibrary/Library8.jpg";
import LibraryImg9 from "./../../assets/images/ComputerClasswithLibrary/Library9.jpg";

import ComputerClass5 from "./../../assets/images/ComputerClasswithLibrary/ComputerClass2.jpeg";
import LibraryImg3 from "./../../assets/images/ComputerClasswithLibrary/Library6.jpg";

// Events Images Array
const eventsImages = [
  {
    name: "Felicitation Ceremony 2024",
    images: [
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guests.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation_Guest1.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation1.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation14.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation2.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation3.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation4.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation5.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation6.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation7.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation8.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation9.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation10.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation11.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation12.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation13.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation15.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation16.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation17.jpg",
      "https://tiet-congrats.s3.ap-south-1.amazonaws.com/Filicitation18.jpg",
    ],
  },
  {
    name: "Motivational Programs",
    images: [
      Motivation3,
      Motivation10,
      Motivation9,
      Motivation2,
      Motivation5,
      Motivation6,
      Motivation7,
      Motivation8,
    ],
  },
  {
    name: "Educational Counsellings",
    images: [Counselling1, Counselling2, Counselling3, Counselling5],
  },
  {
    name: "Public Talks",
    images: [
      PublicTalk4,
      PublicTalk5,
      PublicTalk2,
      PublicTalk1,
      PublicTalk6,
      PublicTalk7,
      PublicTalk8,
      PublicTalk9,
    ],
  },
  {
    name: "Educational Tours",
    images: [
      EducationalTour1,
      EducationalTour2,
      EducationalTour3,
      EducationalTour4,
      EducationalTour5,
      EducationalTour6,
      EducationalTour7,
      EducationalTour8,
    ],
  },
  {
    name: "Computer Class",
    images: [ComputerClassImg4, ComputerClass5, ComputerClassImg1, LibraryImg3],
  },
  {
    name: "TIET Library",
    images: [LibraryImg1, LibraryImg7, LibraryImg8, LibraryImg9],
  },
];

// Carousel Component
const CarouselModal = ({ open, onClose, images, initialIndex }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialIndex,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
  };

  if (!open) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      bgcolor="rgba(0, 0, 0, 0.8)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      zIndex={1000}
      onClick={onClose}
    >
      <Box width="80%" height="80%" onClick={(e) => e.stopPropagation()}>
        <Slider {...settings}>
          {images.map((image, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={image}
                alt={`Carousel Image ${index + 1}`}
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

// Main Component
const WorkshopAndEvents = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (event, index) => {
    setSelectedEvent(event);
    setCurrentIndex(index);
  };

  return (
    <>
      <Banner
        Image={FundRaise}
        BannerHeading={"Workshop & Events"}
        IsHomeBanner={false}
      />
      <Box>
        <Container>
          {eventsImages.map((event, index) => (
            <Box key={index} marginBottom={4} paddingTop={3}>
              <Typography
                align="center"
                variant="h5"
                fontWeight={"bold"}
                gutterBottom
              >
                {event.name}
              </Typography>
              <Grid container spacing={3} justifyContent="center">
                {event.images.map((image, imageIndex) => (
                  <Grid key={imageIndex} item xs={6} sm={3}>
                    <img
                      src={image}
                      alt={`${event.name} ${imageIndex + 1}`}
                      style={{
                        width: "100%",
                        height: "150px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(event, imageIndex)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Container>
      </Box>

      {selectedEvent && (
        <CarouselModal
          open={!!selectedEvent}
          onClose={() => setSelectedEvent(null)}
          images={selectedEvent.images}
          initialIndex={currentIndex}
        />
      )}
    </>
  );
};

export default WorkshopAndEvents;
