import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import Banner from "../../constants/Banner/Banner";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import partner1 from "../../assets/images/School_Partners/Alfalah_school.png";
import partner2 from "../../assets/images/School_Partners/GreenValley_School.jpg";
import partner3 from "../../assets/images/School_Partners/Colleege.jpg";
import AlfalahImg1 from "../../assets/images/School_Partners/AlFalahSchool/Image1.jpeg";
import AlfalahImg2 from "../../assets/images/School_Partners/AlFalahSchool/Image2.jpeg";
import AlfalahImg3 from "../../assets/images/School_Partners/AlFalahSchool/Image3.jpeg";
import GridContent from "../../constants/GridContent/GridContent"; // Import GridContent
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";

import SchoolBanner from "../../assets/images/School_Img.jpg"; // Import the SchoolBanner image directly

const AlfalahSchool = [
  {
    Image: AlfalahImg1,
  },
  {
    Image: AlfalahImg2,
  },
  {
    Image: AlfalahImg3,
  },
];

const SchoolPartnersCarousels = [partner1, partner2, partner3];

const SchoolPartnerShips = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    className: "center",
    centerMode: true,
    centerPadding: "0", // Reduce space between images
  };

  return (
    <>
      <Banner
        Image={SchoolBanner}
        BannerHeading={"Building bridges beyond boundaries"}
        IsHomeBanner={false}
        p={8}
      />

      <Box className={"sections"} p={8} px={{xs: 4, md: 8}}>
        <Container>
          <p className="quotedPara">
            <FormatQuoteIcon />
            At TIET, we believe that education is a fundamental right for all
            children. We are dedicated to creating a positive difference in our
            society by raising awareness about important topics through our
            school partnerships program. By cooperating with schools, we hope to
            reach out to the next generation and provide them with the
            information and skills they need to build a better future. Through a
            variety of activities, we assist schools in creating awareness
            programs on critical themes such as environmental conservation,
            mental health, gender equality, and others. Our mission is to
            inspire and educate children to become responsible citizens who can
            contribute to the advancement of our society.
          </p>
        </Container>
      </Box>
      <Box className={"sections"} p={8} px={{xs: 0, md: 8}}>
        <Container>
          <Typography
            textAlign={"center"}
            variant="h4"
            mb={5}
            fontWeight={"bold"}
          >
            AL-Falah Nursery and Primary School
          </Typography>
          <Grid container spacing={3}>
            {AlfalahSchool.map((AlfalahSchool, key) => (
              <Grid item md={4} key={key}>
                <GridContent
                  GridImage={AlfalahSchool.Image}
                  Heading={AlfalahSchool.Title}
                  Paragraph={AlfalahSchool.Paragraph}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      <Box
        className={"sections"}
        p={8} px={{xs: 0, md: 8}}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container style={{ maxWidth: "800px" }}>
          {" "}
          {/* Limit container width */}
          <Typography
            textAlign={"center"}
            variant="h5"
            mb={5}
            fontWeight={"bold"}
          >
            Our School Partners
          </Typography>
          <Slider
            {...settings}
            className="partnerSlider"
            style={{ margin: "auto" }}
          >
            {SchoolPartnersCarousels.map((val, i) => (
              <img
                key={i}
                src={val}
                alt={i}
                style={{ height: "100px", width: "auto" }}
              />
            ))}
          </Slider>
        </Container>
      </Box>
    </>
  );
};

export default SchoolPartnerShips;
