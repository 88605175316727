import React from "react";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./style.scss";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import XIcon from '@mui/icons-material/X';
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

let SocialMedia = [
  {
    link: "https://www.facebook.com/199995206793030",
    icon: <FacebookOutlinedIcon />,
  },
  {
    link: "https://www.twitter.com/tietpbt",
    icon: <XIcon />,
  },
  {
    link: "https://www.instagram.com/tietpbt",
    icon: <InstagramIcon />,
  },
  {
    link: "https://www.youtube.com/channel/UCogMV1pIWXhkwed1z4wPoEQ/videos",
    icon: <YouTubeIcon />,
  },
];

let FooterLinks = [
  {
    link: "/aboutUs",
    pageName: "About Us",
  },
  {
    link: "/workshopAndEvents",
    pageName: "Gallery",
  },
  {
    link: "/education",
    pageName: "Education",
  },
  {
    link: "/contactUs",
    pageName: "Contact Us",
  },
];

const AppFooter = () => {
  return (
    <Box py={8} className="footerSection">
      <Container>
        <Box display={"flex"}>
          <List>
            {SocialMedia.map((value, key) => (
              <ListItem key={key}>
                <ListItemButton component="a" target="_blank" href={value.link}>
                  <ListItemIcon>{value.icon}</ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box display={"flex"}>
          <List
            display={"flex"}
            sx={{ flexDirection: { xs: "column", sm: "row" } }}
          >
            {FooterLinks.map((value, key) => (
              <ListItem key={key}>
                <ListItemButton component={Link} to={value.link}>
                  <ListItemText primary={value.pageName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Box textAlign={"center"}>
          Copyright © 2024 TIET - All Rights Reserved.
        </Box>
      </Container>
    </Box>
  );
};

export default AppFooter;
